/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    h3: "h3"
  }, _provideComponents(), props.components), {TableOfContents, SideBySide, ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "love-at-first-sound",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#love-at-first-sound",
    "aria-label": "love at first sound permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Love at First Sound!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Xperience is a revolutionary technology platform from Signia, the company that has taken over Siemens’ well-known range of audiological devices. Available in Behind-the-Ear and Receiver-in-Canal models, Xperience is one of the most advanced hearing aid technologies around. As the manufacturer states, it has been “designed for professionals,” meaning that it packs plenty of high-end features that make life easier for wearers. From settings that make the wearer’s own voice sound more natural and easier on the ear, to soundscape processing which makes it possible to pick out conversations in the noisiest, most complex environments, Signia has added a variety of functions which set this hearing aid apart from its competitors. Let’s look at them in a little more detail and find out whether Signia’s Xperience hearing aids are right for you."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-sound-of-your-own-voice-with-ovp",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-sound-of-your-own-voice-with-ovp",
    "aria-label": "the sound of your own voice with ovp permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The Sound of Your Own Voice with OVP"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The central feature of Signia Xperience is called “own voice processing.” This feature helps with one of the most fundamental issues in hearing aid design: the distortion of the user’s own voice, leading to discomfort and poor sound quality. This is generally caused by occlusion, where sound waves generated by the body pass through tissues and bones and are interpreted by the ears alongside sound waves collected by the hearing aid’s microphone."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Xperience seeks to work around this problem via a technology called the Ultra HDe2e binaural link. This focuses solely on the user’s voice, processing it to compensate for occlusion, and delivering a version which imitates how it should sound naturally. And it does so dynamically, meaning that constant adjustments are made depending on where you go, the ambient noise environment, and how loudly you talk. An advantage of the Xperience technology is that the own voice settings can be adjusted independently of its other functions."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "So, you can have separate settings for your own voice, loud environments or your favorite music. It all adds up to a more flexible system for people who are sensitive to the distortion created by their own voice, potentially resulting in a higher quality of audio performance."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "unprecedented-clarity",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#unprecedented-clarity",
    "aria-label": "unprecedented clarity permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Unprecedented Clarity"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia_pure.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "The same technology which underlies Xperience’s own voice processing abilities has been harnessed to detecting the voices of others. By using advanced beam detection sensors, the hearing aid can zero in on specific individuals, resulting in an exceptional degree of spatial awareness. So, when you are dining in a loud restaurant or trying to understand a lively discussion at work, you can easily pick out specific sentences from individuals, instead of their words getting lost in the background.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This directional capability is complemented by one of the most powerful signal qualities on the market.\nSignia’s Xperience is able to handle an input of 113dB, making it suited to use even in loud settings, and it also has a broad dynamic range, which enhances its general sound quality. Both the own voice and directional abilities of the hearing aid are based around Signia’s path-breaking technology, which is as close as any hearing aid has yet come to mimicking the way that our ears detect and filter different elements. This precision one of the key reasons these hearing aids are marketed at “professionals.”"), "\n", React.createElement(ButtonCta, {
    copy: "START YOUR RISK-FREE TRIAL",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "set-up-for-streaming-fans",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#set-up-for-streaming-fans",
    "aria-label": "set up for streaming fans permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Set up for Streaming Fans"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Aside from Xperience’s advanced sound processing features, it is also optimized for streaming thanks to its Bluetooth wireless components. On one hand, Bluetooth underlies their binaural capabilities, transmitting audio between the left and right ears seamlessly. On the other, it allows the Xperience hearing aids to connect to digital devices such as Samsung or Apple smartphones, iPads, mp3 players, TVs, or stereo systems. With a speed of 2.4 GHz, the Bluetooth connection is more than capable of managing high-quality audio streams, whether you are watching a TV show or listening to a radio broadcast. And it manages to do so without compromising on size. Xperience devices use a laser antenna that occupies hardly any space, ensuring that the casing can be kept as lightweight and small as possible."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-mycontrol-app",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-mycontrol-app",
    "aria-label": "the mycontrol app permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The myControl App"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Another major feature of Signia’s Xperience technology (and Signia’s latest hearing aids in general) is its ability to work with the myControl app. On a practical level, this can be very useful for frequent fliers, who may not be able to use their Bluetooth streaming during flights. The myControl app can enable wearers to use Signia’s “TeleLink” to connect two hearing aids together, delivering high-quality performance when Bluetooth isn’t available. Additionally, the app allows you to control your hearing aid in incredible detail.\nFor instance, users can manipulate the microphones by 360 degrees, letting them focus precisely on particular noise sources. Users can also use the app to set up pre-programmed settings, change the volume, and manage a range of streaming devices. And it’s available for iOS and Android, so almost all smartphones should be covered."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "a-user-friendly-high-quality-hearing-aid",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#a-user-friendly-high-quality-hearing-aid",
    "aria-label": "a user friendly high quality hearing aid permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "A User-Friendly, High Quality Hearing Aid"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When all of these features are combined with Signia’s commitment to customer service, the results are devices that offer both advanced tech and ease of use. For instance, Xperience users can access the Signia TeleCare service, which allows them to work with audiologists to fine-tune their hearing aids. Instead of booking appointments and wasting time with clinical visits, audiologists can work remotely with patients – a much more efficient way to do things. However, as you might expect, Xperience technology is not a budget hearing aid, retailing at a substantially higher price than other Signia models like primax technology. Then again, for professionals who appreciate its features, the extra cost will be worth it."), "\n", React.createElement(ButtonCta, {
    copy: "START YOUR RISK-FREE TRIAL",
    classes: "c-cta--content"
  }), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-nx-logo.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "signia-nx-technology",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signia-nx-technology",
    "aria-label": "signia nx technology permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.a, {
    href: "/hearing-aids/brands/signia/x",
    className: "c-md-a"
  }, "Signia Nx Technology")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Xperience is a revolutionary technology platform from Signia, the company that has taken over Siemens’ well-known range of audiological devices. Available in Behind-the-Ear and Receiver-in-Canal models, Xperience is one of the most advanced hearing aid technologies around.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-silk.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "signia-silk-x",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signia-silk-x",
    "aria-label": "signia silk x permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.a, {
    href: "/hearing-aids/brands/signia/silk",
    className: "c-md-a"
  }, "Signia Silk X")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re looking for a hearing aid that is as small as a coffee bean and unnoticeable to those around you, the Signia Silk X is the perfect fit. It’s one of the smallest hearing aids on the market today, disappearing inside your ear. These ITE hearing aids also include “Click Sleeves,” which make them simple and comfortable to wear, as they’re made out of soft silicone that molds itself to the shape of your ear canal.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-styletto-dark-granite.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "signia-styletto",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signia-styletto",
    "aria-label": "signia styletto permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.a, {
    href: "/hearing-aids/brands/signia/styletto",
    className: "c-md-a"
  }, "Signia Styletto")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "This slim, behind-the-ear Bluetooth hearing aid has transformed the future of modern hearing aid design. Designed for the active lifestyle, the rechargeable Styletto X hearing aid combines elegance, convenience and functionality to deliver a high-quality hearing experience without compromising style. And no one will know you’re wearing it.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/binaural-hearing-aids.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "signia-pure",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signia-pure",
    "aria-label": "signia pure permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.a, {
    href: "/hearing-aids/brands/signia/pure",
    className: "c-md-a"
  }, "Signia Pure")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Signia range of Pure hearing aids is incredible no matter your level of hearing loss. With these powerful Bluetooth hearing aids that sit discreetly behind your ear, you’ll be able to stream phone calls, TV and audio right into your ears.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
